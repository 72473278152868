import {
  trapFocusOnModal
} from "./trap-focus-on-modal";
import easyToggle from 'easy-toggle-state';

(function () {
  easyToggle()
  const ESC_KEY = 27;
  const body = document.querySelector('body');
  const autofocusedInput = document.querySelector('.Dialog input:not([type=hidden])');
  let prevActiveElement;

  const updateDialog = (dialog) => {
    prevActiveElement = document.activeElement;
    body.style.overflow = 'hidden';
    dialog.setAttribute('aria-hidden', 'false');

    if(autofocusedInput) {
      setTimeout(() => {
        autofocusedInput.focus();
      }, 100);
    }

    if (dialog.classList.contains('is-active')) {
      trapFocusOnModal(dialog);
    }
  }

  const closeDialog = (dialog) => {
    dialog.setAttribute('aria-hidden', 'true');
    dialog.classList.remove('is-active');
    body.style.overflow = 'visible';

    setTimeout(() => {
      prevActiveElement.focus();
    }, 100);
  };

  const closeDialogWithEsc = evt => {
    if (evt.keyCode === ESC_KEY) {
      evt.preventDefault();
      const activeDialog = evt.target.closest('.Dialog');

      closeDialog(activeDialog);
    }
  };

  const dialogTriggers = document.querySelectorAll('.jsDialogTrigger');
  const dialogCloseButtons = document.querySelectorAll('.jsDialogCloseButton');
  const dialogBackdrop = document.querySelector('.jsDialogBackdrop');

  window.addEventListener('keydown', closeDialogWithEsc);

  dialogTriggers.forEach(trigger => trigger.addEventListener('click', function () {
    const dialog = this.parentNode.querySelector('.Dialog');
    updateDialog(dialog);
  }));

  dialogCloseButtons.forEach(button => button.addEventListener('click', function () {
    const dialog = this.closest('.Dialog');
    closeDialog(dialog);
  }));

  if(dialogBackdrop) {
    dialogBackdrop.addEventListener('click', function () {
      const dialog = this.closest('.Dialog');
      closeDialog(dialog)
    });
  }
})()
