function replaceTextWithLinks(text) {
  const urlPattern = /(https?:\/\/[^\s()!?:;]+(?=[\s()!?:;]|$))/g;
  return text.replace(urlPattern, '<a href="$1">$1</a>');
}

function messageTemplate(message) {
  return `
    <li id="${message.id}" class="Message-${message.role}">
      <div class="Image--wrap">
        <img
          src="/assets/new_anadea/anadea/${message.role === "user" ? "user" : "chat_logo"}.svg"
          alt="${message.role}">
      </div>
      <p class="Message-text">${replaceTextWithLinks(message.content[0].text?.value)}</p>
    </li>
  `;
}

const loadingTemplate = `
  <li id="messageLoading" class="Message-assistant">
    <div class="Image--wrap">
      <img src="/assets/new_anadea/anadea/chat_logo.svg" alt="chat">
    </div>
    <p class="Message-text">Assistant is typing<span class="dot-loader"></span></p>
  </li>
`;

function scrollToLastItem() {
  const messageContainer = $('.Message-container');
  const messageList = $('#messages_data');

  messageContainer.scrollTop(messageList.prop('scrollHeight'));
}

function getChatBotMessagesData(callback) {
  $.getJSON("/api/chat_bot", callback);
}

function sendMessageToChatBot(text, callback) {
  $.ajax({
    url: '/api/chat_bot',
    type: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ message: text }),
    success: callback
  });
}

function renderMessages(messagesData) {
  const messageList = $('#messages_data');

  messagesData.data.reverse().forEach((item, i) => {
    const message = document.getElementById(item.id);
    const htmlToAppend = message ? null : messageTemplate(item);

    if (htmlToAppend) {
      messageList.append(htmlToAppend);
      scrollToLastItem();
    }
  });
}

function changeButtonAvailability({ isAvailabile }) {
  $('#message_form_submit_button').prop("disabled", !isAvailabile);
}

$(document).ready(function() {
  const chatBot = $('#chatBotJs');
  const messageForm = $('#message_form');
  const messagesContainer = $('#messages_data');
  const openChatButton = $('#open_chat_button');
  const submitButton = $('#message_form_submit_button');
  const resizeButton = $('#resize_chat_button');

  openChatButton.on('click', function() {
    const isMobile = window.screen.width < 768;

    if(!isMobile) {
      $('body').css('overflow', 'visible');
    }
    getChatBotMessagesData(renderMessages);
  });

  messageForm.find('#message_form_textarea').on('input', function() {
    submitButton.toggleClass('active', this.value.trim() !== '');
  })

  messageForm.on('submit', function(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const textarea = document.getElementById('message_form_textarea');
    textarea.value = '';

    changeButtonAvailability({ isAvailabile: false });
    $(messagesContainer).append(
      messageTemplate({ id: 'currentMessage', role: 'user', content: [{ text: { value: data.message } }] }),
      loadingTemplate
    );

    sendMessageToChatBot(data.message, function(newMessagesData) {
      changeButtonAvailability({ isAvailabile: true });
      $('#messageLoading, #currentMessage').remove();
      renderMessages(newMessagesData);
    });
  });

  messageForm.keydown(function(event) {
    if (event.key === 'Enter' && !submitButton.disabled) {
      event.preventDefault();
      submitButton.click();
    }
  });

  resizeButton.on('click', function() {
    chatBot.toggleClass('FormChat--big');
  });
});
