const TAB_KEY = 9;

export const trapFocusOnModal = element => {
  var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled])');
  var firstFocusableEl = focusableEls[0];
  var lastFocusableEl = focusableEls[focusableEls.length - 1];
  element.addEventListener('keydown', function (evt) {
    var isTabPressed = (evt.key === 'Tab' || evt.keyCode === TAB_KEY);

    if (!isTabPressed) {
      return;
    }

    if (evt.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        evt.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        evt.preventDefault();
      }
    }
  });
};
